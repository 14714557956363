import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import GlobalStyle from './globalStyles';
import ContactForm from './components/ContactForm';
import backgroundImage from './assets/background_nyc_cityscape.webp';

const App: React.FC = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [backgroundImageUrl, setBackgroundImageUrl] = useState(backgroundImage);

    useEffect(() => {
        const img = new Image();
        img.src = backgroundImage;
        // const minimumDelay = 1250;
        //
        // const startTime = Date.now();
        //
        img.onload = () => {
            setBackgroundImageUrl(img.src);
            // const elapsedTime = Date.now() - startTime;
            // const remainingTime = minimumDelay - elapsedTime;
            //
            // if (remainingTime > 0) {
            //     setTimeout(() => {
            //         setIsLoading(false);
            //     }, remainingTime);
            // } else {
            //     setIsLoading(false);
            // }
        };
        //
        // // If the image is already cached, manually trigger the logic as if it loaded instantly
        // if (img.complete) {
        //     setBackgroundImageUrl(img.src);
        //     setTimeout(() => {
        //         setIsLoading(false);
        //     }, minimumDelay);
        // }
    }, []);

    return (
        <>
            <GlobalStyle />
            {/*{isLoading ? (*/}
            {/*    <Pre>*/}
            {/*        <LoaderOverlay>*/}
            {/*            <Loader color="rgb(255 110 110 / 60%)" />*/}
            {/*        </LoaderOverlay>*/}
            {/*    </Pre>*/}
            {/*) : (*/}
                <MainContainer style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
                    <Overlay>
                        <ContactForm />
                    </Overlay>
                </MainContainer>
            {/*)}*/}
        </>
    );
};

export default App;

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black// #202020;
`;

const Overlay = styled.div`
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 2rem;
  text-align: center;
  max-width: 600px;
  width: 100%;
  color: white;
  position: relative;
  z-index: 1;
`;

const Pre = styled.div`
  background-color: #202020;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Loader = styled.div`
  border: 8px solid #f3f3f3;
  border-top: 8px solid rgb(255 110 110 / 60%);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: ${rotate} 1s linear infinite;
`;

const LoaderOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
`;
