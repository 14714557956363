import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import axios from "axios";
import {FaCheckCircle, FaArrowRight, FaArrowLeft} from 'react-icons/fa';

const ContactForm: React.FC = () => {
    const [fullName, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isSending, setIsSending] = useState(false);
    const [isSent, setIsSent] = useState(false);

    const awsApi = 'https://api.zygmuntbuszyk.com';

    useEffect(() => {
        const sentStatus = localStorage.getItem('messageSent');
        if (sentStatus) {
            setIsSent(true);
        }
    }, []);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSending(true);

        const requestBody = {
            body: JSON.stringify({
                fullName,
                email,
                message,
            }),
        };

        try {
            const response = await axios.post(`${awsApi}/send-email`, requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if(response.data?.error) {
                // something went wrong
                return;
            }
            if (response.status === 200) {
                setIsSent(true);
                localStorage.setItem('messageSent', 'true'); // Save sent status
            }
        } catch (error) {
            console.error('There was an error sending the email:', error);
        } finally {
            setIsSending(false);
        }
    };

    const handleSendAnotherMessage = () => {
        // Reset form state
        setName('');
        setEmail('');
        setMessage('');
        setIsSent(false);
        localStorage.removeItem('messageSent'); // Clear the saved sent status
    };

    return (
        <div>
            {!isSent && <Title>Your <Span>Ideas</Span>, brought to life<br />with my <Span>Code.</Span></Title>}

            <FormContainer onSubmit={handleSubmit}>
                {isSent ? (
                    <ConfirmationContainer>
                        <BackArrow onClick={handleSendAnotherMessage}>
                            <FaArrowLeft size={24} />
                        </BackArrow>
                        <FaCheckCircle size={80} color="rgb(255 110 110 / 60%)" />
                        <ConfirmationMessage>
                            Thank you for your <Span>submission.</Span><br />We have received your message and will get back to you <Span>shortly.</Span>
                        </ConfirmationMessage>
                    </ConfirmationContainer>
                ) : (
                    <>
                        {isSending && (
                            <LoaderOverlay>
                                <Loader color="rgb(255 110 110 / 60%)" />
                            </LoaderOverlay>
                        )}
                        <InputField
                            type="text"
                            placeholder="Full Name"
                            value={fullName}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                        <InputField
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <TextareaField
                            placeholder="Tell me what you want me to build..."
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                        />
                        <SubmitButton type="submit" disabled={isSending}>
                            Send Message
                        </SubmitButton>
                    </>
                )}
            </FormContainer>
        </div>
    );
};

export default ContactForm;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const InputField = styled.input`
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 5px;
  border: 2px solid transparent; /* Default border color */
  font-size: 1rem;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: rgb(255 110 110 / 60%); /* Border color on focus */
    outline: none; /* Remove default outline */
  }
`;

const TextareaField = styled.textarea`
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 5px;
  border: 2px solid transparent; /* Default border color */
  font-size: 1rem;
  resize: none;
  height: 150px;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: rgb(255 110 110 / 60%); /* Border color on focus */
    outline: none; /* Remove default outline */
  }
`;

const SubmitButton = styled.button`
  padding: 1rem;
  border-radius: 5px;
  border: none;
  background-color: rgb(255 110 110 / 60%);
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background-color: rgb(255 110 110 / 70%);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
  }

  &:disabled {
    background-color: gray;
    cursor: not-allowed;
  }
`;

const LoaderOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Loader = styled.div`
  border: 8px solid #f3f3f3;
  border-top: 8px solid rgb(255 110 110 / 60%);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: ${rotate} 1s linear infinite;
`;

const ConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
`;

const ConfirmationMessage = styled.p`
  margin-top: 1rem;
  font-size: 1.5rem;
  color: white;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 2rem;
  font-weight: bold;
`;

const Span = styled.span`
  color: rgb(255 110 110 / 60%);
  font-weight: bolder;
`;

const BackArrow = styled.div`
  position: absolute;
  top: -25px;
  left: -25px;
  cursor: pointer;
  color: rgb(255 110 110 / 60%);
  padding: 1rem;
  transition: color 0.3s ease;

  &:hover {
    color: rgb(255 110 110 / 80%);
  }
`;
